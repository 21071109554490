/***    FONDO PARA SITIO WEB    ***/
body {
    background-image: linear-gradient(to right, rgb(215,215,215), rgb(245,245,245), rgb(245,245,245), white, white, white, rgb(245,245,245), rgb(245,245,245), rgb(215,215,215));
}

/***    COLOR DE TEXTO PARA ETIQUETAS EN FORMS, ENCABEZADOS DE FORMS Y BOTON DE ACCORDIONS  ***/
#login-box h3, .navbar-nav a.dropdown-item:hover, .form-group .label, .form-group label {
    color: #0a58ca !important;
}

/***    DISEÑO PARA FORM DEL LOGIN ***/
#login-box {
    margin: 0 auto;
    border-radius: 15px !important;
    border: none;
}

/***    ACOMODO SUPERIOR PARA LOS DIV.CONTAINER'S EN SITIO WEB  ***/
div.App > div.container {
    margin-top: 30px !important;
}

/***    ESTILOS PARA LA BARRA DEL MENU  ***/
.custom-theme {
    background-image: linear-gradient(to bottom, rgb(0, 75, 187),rgb(0, 113, 219),rgb(64, 150, 248)) !important;
    color: white;
}
.navbar-brand, .navbar-nav .dropdown-toggle.nav-link, .navbar-collapse .btn-outline-success, #basic-navbar-nav a.nav-link, .navbar-toggler-icon {
    color: white;
}

/* .dropdown-toggle2 {
    color: black !important;
     background-color: #c8ced3 !important; 
} */
#secondlevel {
    color: black !important;
    padding: 0.25rem 1rem;
 /*     background-color: #c8ced3 !important;  */
}

/* #secondlevel a:link { color: #000000 !important; }
#secondlevel a:visited { color: #000000 !important; }
#secondlevel a:hover { color: #000000 !important; }
#secondlevel a:active { color: #000000 !important; } */

/***    ESTILO DE LETRA PARA ELEMENTOS DE ACCORDION     ***/
.accordion-button.collapsed {
    background-image: linear-gradient(to bottom, #c7c7c7, #c7c7c7, #e9e9e9, #f3f3f3) !important;
}
.accordion-button:not(.collapsed) {
    background-image: linear-gradient(to bottom, #8fcafd, #bedaff, #e5eefb);
    font-size: 13pt;
}

@media all and (min-width: 480px) {
    #login-box {
        border: 3px outset #ddd !important;
    }
}